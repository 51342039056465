import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';

import React from 'react';

import Banner from '../components/BannerDefault/index';
import ButtonDefault from '../components/Buttons/ButtonDefault';
import Depoimentos from '../components/Carousel/CarouselDepoimentos';
import MetaTags from '../components/MetaTags';
import useMedia from '../hooks/useMedia';
import { hypeApi } from '../providers/hype-api';
import { customLoader } from '../utils/customLoaderImage';
import { findImage } from '../utils/ImageFindSizes';
import { normalizeString } from '../utils/normalizeString';

import { getCollectionCMS } from './api/payloadCMS';
import Formfale from './home/formfale';
import NossosLancamentos from './home/lancamentos';
import Noticias from './home/noticias';
import Politica from './home/politica';
import SobreHype from './home/sobreHype';
import ValoresHype from './home/valores';

export default function Home({
  homePage, lancamentos, artigos, depoimentos,
}) {
  const router = useRouter();
  const rota = `https://hypeempreendimentos.com.br${router.asPath}`;

  const isMobile = useMedia('(max-width: 768px)');

  return (
    <>
      <Head>
        <MetaTags
          data={{
            titulo: homePage?.seo?.seo?.metaTitle,
            descricao: homePage?.seo?.seo?.metaDescription,
            imagem: homePage?.seo?.seo?.ogImage?.url,
            rota,
          }}
        />
      </Head>
      <Banner
        image={{ src: findImage(homePage?.imagens, 'banner-principal')?.imagem?.url }}
        width='100%'
        height={isMobile ? '30vh' : '70vh'}>
        <h1>
          {homePage.textos.find((txt) => txt.referencia === 'home-titulo-banner-principal').texto.conteudo.length > 3
            ? homePage.textos.find((txt) => txt.referencia === 'home-titulo-banner-principal').texto.conteudo
            : ''}
        </h1>
        {homePage?.textos?.find((txt) => txt.referencia === 'home-button-banner-principal')?.texto.conteudo.length > 3 ? (
          <ButtonDefault
            aria={homePage?.textos?.find((txt) => txt.referencia === 'home-button-banner-principal')?.texto.titulo}
            url={homePage?.textos?.find((txt) => txt.referencia === 'home-button-banner-principal')?.texto.conteudo}
            text={homePage?.textos?.find((txt) => txt.referencia === 'home-button-banner-principal')?.texto.titulo}
            target='_blank'
          />
        ) : null}
        <Link
          href='#quemsomos'
          passHref>
          <Image
            width='30px'
            height='30px'
            src='/img/arrowdown.svg'
            loader={customLoader}
            alt='Ir para o texto quem somos'
            priority
            style={{ cursor: 'pointer' }}
          />
        </Link>
      </Banner>
      <SobreHype
        dados={{
          banner: homePage?.imagens.find((img) => img.referencia === 'home-quem-somos-imagem'),
          texto1: homePage?.textos.find((txt) => txt.referencia === 'home-quem-somos-1'),
          texto2: homePage?.textos.find((txt) => txt.referencia === 'home-quem-somos-2'),
          texto3: homePage?.textos.find((txt) => txt.referencia === 'home-quem-somos-3'),
        }}
      />
      {depoimentos && <Depoimentos depoimentos={depoimentos} />}
      {lancamentos && (
        <NossosLancamentos
          texto={homePage?.textos.find((txt) => txt.referencia === 'home-lancamentos')}
          lancamentos={lancamentos}
        />
      )}

      <Politica
        dados={{
          banner: homePage?.imagens.find((img) => img.referencia === 'home-qualidade-imagem'),
          icones: homePage?.imagens?.filter((img) => img.referencia.includes('home-qualidade-politica')),
          textos: homePage?.textos?.filter((txt) => txt.referencia.includes('home-qualidade')),
        }}
      />
      <ValoresHype
        dados={{
          banner: homePage?.imagens.find((img) => img.referencia === 'home-valores-banner'),
          texto: homePage?.textos?.find((txt) => txt.referencia === 'home-valores-texto'),
          icones: homePage?.imagens?.filter((img) => img.referencia.includes('home-valores-icone')),
          textoIcones: homePage?.textos?.filter((txt) => txt.referencia.includes('home-valores-icone')),
          background: homePage?.imagens?.find((img) => img.referencia === 'home-valores-background'),
        }}
      />
      <Noticias
        artigos={artigos}
        background={homePage?.imagens.find((img) => img.referencia === 'home-noticias-background')}
      />
      <Formfale />
    </>
  );
}

export async function getStaticProps() {
  const homePageRes = await getCollectionCMS('paginas');
  const homePage = homePageRes.find((page) => page.pagina === '/home');

  const hypeApiEmpreendimentoRes = await hypeApi.get('/empreendimento');
  const hypeApiEmpreendimentos = hypeApiEmpreendimentoRes.data;

  const getEmpreendimentosCMS = await getCollectionCMS('empreendimentos');
  const cmsEmpreendimentos = getEmpreendimentosCMS.filter((emp) => emp.visualizacao !== 'rascunho');
  const cmsArtigos = await getCollectionCMS('artigos');

  const lancamentos = await hypeApiEmpreendimentos.reduce((prev, curr) => {
    const hypeApiEmpreendimento = curr;

    const cmsMatchEmpreendimento = cmsEmpreendimentos.find(
      (emp) => normalizeString(emp.empreendimento) === normalizeString(hypeApiEmpreendimento.nome),
    );

    if (cmsMatchEmpreendimento) {
      const lancamento = {
        image: {
          url: cmsMatchEmpreendimento?.imagens?.find((img) => img.referencia === 'thumbnail')?.imagem?.url || '',
          height: 600,
          width: 600,
        },
        id: cmsMatchEmpreendimento.id.toString(),
        description: cmsMatchEmpreendimento.textos.find((txt) => txt.referencia === 'conceito').texto.conteudo ?? '',
        link: `/apartamentos-a-venda/${cmsMatchEmpreendimento.slug}`,
        title: hypeApiEmpreendimento.nome,
        createdAt: cmsMatchEmpreendimento.createdAt.toString(),
        slug: cmsMatchEmpreendimento.slug,
      };

      return [...prev, lancamento];
    }

    return prev;
  }, []);

  lancamentos.sort((la, lb) => {
    const lancamentoADate = new Date(la.createdAt);
    const lancamentoBDate = new Date(lb.createdAt);

    return lancamentoBDate.getTime() - lancamentoADate.getTime();
  });

  const artigos = cmsArtigos.map((art) => ({
    imagem: {
      alt: art.conteudo.imagemDestacada.alt ?? '',
      width: art.conteudo.imagemDestacada.width.toString(),
      height: art.conteudo.imagemDestacada.height.toString(),
      url: art.conteudo.imagemDestacada.url,
    },
    slug: art.slug,
    title: art.conteudo.titulo,
    text: art.conteudo.conteudo,
    createdAt: art.createdAt.toString(),
  }));

  artigos.sort((blogA, blogB) => {
    const blogADate = new Date(blogA.createdAt);
    const blogBDate = new Date(blogB.createdAt);

    return blogBDate.getTime() - blogADate.getTime();
  });

  const depoimentos = await getCollectionCMS('depoimentos');

  lancamentos.length = 5;
  artigos.length = 5;

  const imagens = await getCollectionCMS('imagens');
  const logo = imagens.find((img) => img.titulo === 'logo')?.url;

  const listas = await getCollectionCMS('listas');
  const routes = listas?.find((lt) => lt.tituloLista === 'Site - header')?.itens;

  const getSedes = await getCollectionCMS('sedes');
  const address = getSedes?.filter((sede) => ['Sede 1', 'Americana'].includes(sede.nome));

  const footerIcons = imagens?.filter((img) => ['facebook_icon', 'instagram_icon', 'youtube_icon', 'linkedin_icon', 'logo_escura'].includes(img.titulo),
  );

  const socialMedia = getSedes
    ?.filter((sede) => ['Sede 1'].includes(sede.nome))
    .map(({
      instagram, youtube, facebook, linkedin,
    }) => ({
      instagram,
      youtube,
      facebook,
      linkedin,
    }))[0];

  const getContatos = await getCollectionCMS('contatos');
  const contact = getContatos?.filter((list) => ['Geral'].includes(list.tituloLista))[0].contatos || { contatos: [] };

  const textos = await getCollectionCMS('textos');
  const copy = textos?.find((txt) => txt.titulo === 'Copyright').conteudo;

  const headerData = { logo, routes };
  const footerData = {
    routes,
    logo,
    footerIcons,
    address,
    contact,
    copy,
    socialMedia,
  };

  return {
    props: {
      homePage,
      lancamentos,
      artigos,
      depoimentos,
      footerData,
      headerData,
    },
    revalidate: Number(process.env.REVALIDATE_INTERVAL),
  };
}
