import Image from 'next/image';
import Link from 'next/link';

import { IoIosArrowDropright } from 'react-icons/io';

import { customLoader } from '../../../utils/customLoaderImage';

import style from './cardCarouselBlog.module.scss';

export default function CardCarouselBlog({ artigo }) {
  if (!artigo) return null;

  return (
    <div className={style.item}>
      <h3>{artigo.title}</h3>
      <figure>
        <Image
          src={artigo?.imagem?.sizes?.thumbnail?.url || artigo?.imagem?.url}
          loader={customLoader}
          alt={artigo.imagem.alt || 'Imagem ilustrativa'}
          layout='responsive'
          objectFit='cover'
          quality={100}
          width={artigo.imagem.width}
          height={artigo.imagem.height}
          priority
        />
        <figcaption>
          Imagem:
          {artigo.imagem.alt || 'Imagem ilustrativa'}
        </figcaption>
      </figure>
      <p>
        {artigo.text
          .toString()
          .replace(/(<([^>]+)>)/gi, '')
          .substring(0, 100)
          .concat('...')}
      </p>
      <Link
        href={`/blog/${artigo.slug}`}
        passHref>
        <a>
          <span>Ver mais</span>
          <IoIosArrowDropright />
        </a>
      </Link>
    </div>
  );
}
