import Image from 'next/image';

import Banner from '../../components/BannerDefault';
import ButtonDefault from '../../components/Buttons/ButtonDefault';
import { customLoader } from '../../utils/customLoaderImage';

import style from './politica.module.scss';

export default function Politica({ dados }) {
  if (!dados) return null;

  const { banner, icones, textos } = dados;

  return (
    <Banner
      image={{ src: '/img/backgroundpolitica.webp' }}
      width='100%'
      height='fit-content'>
      <div className={style.section}>
        <div className={style.lineDecorCenter} />
        <figure className={style.image}>
          <Image
            src={banner?.imagem?.sizes?.medium?.url || '/img/image_icon.png'}
            loader={customLoader}
            alt={banner?.alt || ''}
            width={banner?.imagem?.sizes?.medium?.width}
            height={banner?.imagem?.sizes?.medium?.height}
            objectFit='cover'
            layout='responsive'
            priority
          />
          <figcaption>{banner?.alt}</figcaption>
        </figure>

        <div className={style.text}>
          <h2>{textos.find((txt) => txt.referencia === 'home-qualidade')?.texto.titulo}</h2>
          <p>{textos.find((txt) => txt.referencia === 'home-qualidade')?.texto.conteudo}</p>
          <ButtonDefault
            url='/qualidade-hype'
            text='Saiba mais!'
            aria='Saiba mais sobre a Hype!'
          />
        </div>

        <div className={style.destaque}>
          <div className={style.columnDecor} />
          {icones
            && icones.map((icone) => (
              <>
                <div className={style.lineDecor} />
                <div key={icone.referencia}>
                  <div className={style.icon}>
                    <div>
                      <Image
                        src={icone.imagem.url}
                        loader={customLoader}
                        alt={icone.imagem.alt}
                        width={46}
                        height={46}
                      />
                    </div>
                    <p>{textos.find((txt) => txt.referencia === icone.referencia)?.texto.conteudo}</p>
                  </div>
                </div>
              </>
            ))}
        </div>
      </div>
    </Banner>
  );
}
