// eslint-disable-next-line import/prefer-default-export
export const imagemSizeDestructure = (props: any) => {
  const data: any = props?.imagem;

  if (data?.sizes?.large?.url) {
    return data.sizes.large;
  }

  if (data?.sizes?.medium?.url) {
    return data.sizes.medium;
  }

  if (data?.sizes?.small?.url) {
    return data.sizes.small;
  }

  if (data?.sizes?.square?.url) {
    return data.sizes.square;
  }

  if (data?.sizes?.thumbnail?.url) {
    return data.sizes.thumbnail;
  }

  if (data?.url) {
    return data;
  }

  return '';
};

export const imagemSizes = (props: any) => {
  const data: any = props?.imagem;

  if (data?.sizes?.large?.url) {
    return data.sizes.large.url;
  }

  if (data?.sizes?.medium?.url) {
    return data.sizes.medium.url;
  }

  if (data?.sizes?.small?.url) {
    return data.sizes.small.url;
  }

  if (data?.sizes?.square?.url) {
    return data.sizes.square.url;
  }

  if (data?.sizes?.thumbnail?.url) {
    return data.sizes.thumbnail.url;
  }

  if (data?.url) {
    return data.url;
  }

  return '';
};

export const findImage: any = (imagens: any, referencia: any) => imagens?.find((img: any) => img.referencia === referencia);
