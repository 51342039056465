import Image from 'next/image';

import { customLoader } from '../../utils/customLoaderImage';

import style from './valores.module.scss';

export default function ValoresHype({ dados }) {
  if (!dados) return null;

  const {
    banner, texto, icones, textoIcones, background,
  } = dados;

  return (
    <div className={style.section}>
      <figure className={style.imageBackground}>
        <Image
          alt={background?.imagem.alt}
          src={background?.imagem.url}
          loader={customLoader}
          objectFit='cover'
          layout='fill'
        />
        <figcaption>{background?.imagem.alt}</figcaption>
      </figure>

      <figure className={style.image}>
        <Image
          src={banner?.imagem?.sizes?.medium?.url || '/img/image_icon.png'}
          loader={customLoader}
          alt={banner?.alt}
          width={banner?.imagem?.sizes?.medium?.width}
          height={banner?.imagem?.sizes?.medium?.height}
          objectFit='cover'
          layout='responsive'
          priority
        />
        <figcaption>{banner?.alt}</figcaption>
      </figure>

      <div className={style.card}>
        <h2>{texto.texto.titulo}</h2>
        <p>{texto.texto.conteudo}</p>
        <div className={style.icons}>
          {icones.map((icone, index) => (
            <figure key={icone.referencia}>
              <Image
                src={icone.imagem.url}
                loader={customLoader}
                alt={icone.imagem.alt}
                width={45}
                height={45}
              />
              <h3>{textoIcones[index].texto.conteudo}</h3>
              <figcaption>{icone.imagem.alt}</figcaption>
            </figure>
          ))}
        </div>
      </div>
    </div>
  );
}
